//@ts-check

// function to add a "sorted-active" class to the sorted th element
document.addEventListener('DOMContentLoaded', () => {
    const headers = document.querySelectorAll('.filtered-table th[data-sort]');

    headers.forEach(header => {
        header.addEventListener('click', () => {
            // Entfernen der aktiven Klasse von allen Sortier-Links
            headers.forEach(h => h.classList.remove('sorted-active'));

            // Hinzufügen der aktiven Klasse zum angeklickten Sortier-Link
            header.classList.add('sorted-active');
        });
    });
});
