//@ts-check

/**
 * Enables the cards to flip.
 */
const flipCards = () => {
    const flipButtons = document.querySelectorAll('.pricecard__flip-button');
    if (flipButtons) { // check if the element exists
        flipButtons.forEach((button) => {
            button.addEventListener('click', () => {
                const cardOuter = button.closest('.pricecard-flip__outer');
                if (cardOuter) { // check if the element exists
                    cardOuter.classList.toggle('is-flipped');

                    // Check if we are flipping to the front.
                    const isFlippingToFront = !cardOuter.classList.contains('is-flipped');

                    // A slight delay to wait for the flip transition to start.
                    // May adjust based on CSS transition timing.
                    setTimeout(() => adjustFlipCardHeight(cardOuter, isFlippingToFront), 150);
                }
            });
        });
    }
};

/**
* Adjusts the height of the flip card container dynamically based on the currently visible content (front or back).
* @param {HTMLElement} cardOuter - The container element of the flip card.
* @param {boolean} isFlippingToFront - Indicates whether the card is being flipped to its front side.
*/
const adjustFlipCardHeight = (cardOuter, isFlippingToFront) => {
    // Select front and back faces of the card.
    const frontFace = cardOuter.querySelector('.pricecard-flip__inner');
    const backFace = cardOuter.querySelector('.pricecard-flip__inner--back');

    // Calculate the height of both faces.
    let activeHeight = 0;
    if (isFlippingToFront && frontFace) { // check if the element frontFace exists
        activeHeight = frontFace.offsetHeight;
    } else {
        if (backFace) { // check if the element exists
            activeHeight = backFace.offsetHeight;
        }
    }

    // Dynamically adjust the container height to match the active face.
    cardOuter.style.height = `${activeHeight}px`;
};

/**
 * Makes the accordion panels toggle between open and close.
 */
function toggleAccordionPanels() {
    const accordionToogles = document.querySelectorAll('.filtered-table__accordion-toogle');
    const accordionContents = document.querySelectorAll('.filtered-table__accordion-content');
    const panelArrows = document.querySelectorAll('.icon-angle-down');

    if (accordionToogles && accordionContents) { // check if the elements exists
        accordionToogles.forEach((toogle, index) => {
            toogle.addEventListener('click', () => {
                accordionContents[index].classList.toggle('d-none');
                panelArrows[index].classList.toggle('icon-angle-down');
                panelArrows[index].classList.toggle('icon-angle-up');
            });
        });
    }
}

/**
 * Sorts the table in asc or desc order.
 */
function sortTable(header, index) {
    const tableElement = header.closest('table'); // get the closest table element
    const sortOrder = getSortOrder(header); // get the sort order
    const tbodyArray = getTbodyArray(tableElement); // get the an array with all tbody elements
    removeSortingClasses(tableElement); // remove sorting classes
    addSortingClass(header, sortOrder); // add updated sorting class
    sortTbodyArray(tbodyArray, index, sortOrder); // sort the tbody array in asc or desc order
    reappendTbodyArray(tableElement, tbodyArray); // reappend the updated tbody array to the table
}

function getSortOrder(header) {
    return header.classList.contains('sorted-asc') ? 'desc' : 'asc';
}

function removeSortingClasses(tableElement) {
    tableElement.querySelectorAll('thead th').forEach(th => {
        th.classList.remove('sorted-asc', 'sorted-desc');
    });
}

function addSortingClass(header, sortOrder) {
    header.classList.add(`sorted-${sortOrder}`);
}

function getTbodyArray(tableElement) {
    const tbodyElements = tableElement.querySelectorAll('tbody');
    return Array.from(tbodyElements);
}

function sortTbodyArray(tbodyArray, index, sortOrder) {
    tbodyArray.sort((a, b) => {
        const aValue = getSortableValue(a, index);
        const bValue = getSortableValue(b, index);
        return sortOrder === 'asc' ? aValue - bValue : bValue - aValue;
    });
}

function getSortableValue(tbody, index) {
    let cell; // Initialize a variable to hold the targeted cell

    let elementsArray = Array.from(tbody.querySelectorAll('tr td')); // Get array of all 'td' elements within 'tr' elements inside  given 'tbody'

    let sortableColumns = elementsArray.filter(td => td.hasAttribute('data-order')); // Filter the 'td' elements to include only those that have the 'data-order' attribute

    let amountOfSortableColumns = sortableColumns.length; // Determine the number of sortable columns

    // Select the appropriate cell based on the provided index If the index is within the range of sortable columns, use it directly
    // Otherwise, wrap around using modulo to handle cases where the index exceeds the number of sortable columns
    if (index < amountOfSortableColumns) {
        cell = sortableColumns[index];
    } else {
        cell = sortableColumns[index % amountOfSortableColumns];
    }

    return cell && cell.dataset.order ? parseFloat(cell.dataset.order) : 0; // Return value of 'data-order' attribute
}

function reappendTbodyArray(tableElement, tbodyArray) {
    tbodyArray.forEach(tbody => {
        tableElement.appendChild(tbody);
    });
}

document.addEventListener('DOMContentLoaded', function () {
    flipCards();

    toggleAccordionPanels();

    const filteredTableAllDataSort = document.querySelectorAll('.filtered-table thead th[data-sort]');
    if (filteredTableAllDataSort) { // check if the element exists
        filteredTableAllDataSort.forEach((header, index) => {
            header.addEventListener('click', function () {
                sortTable(this, index);
            });
        });
    }
});
// function to check the order of an array
function checkArrayOrder(arr) {
    if (arr.length <= 1) return 'short';

    let ascending = true;
    let descending = true;

    for (let i = 1; i < arr.length; i++) {
        if (arr[i] < arr[i - 1]) ascending = false;
        if (arr[i] > arr[i - 1]) descending = false;
    }

    if (ascending) return 'sorted-asc';
    if (descending) return 'sorted-desc';
    return 'unsorted';
}

// function to set the sorting class on the th elements if the data is sorted
const updateSortingClasses = function () {
    const filteredTables = document.querySelectorAll('.filtered-table');
    filteredTables.forEach((table) => {
        const ths = table.querySelectorAll('th');
        ths.forEach((th, index) => {
            const tds = table.querySelectorAll(`.filtered-table > tbody > tr > [data-order]:nth-child(${index + 1})`);
            const values = Array.from(tds).map(td => parseFloat(td.dataset.order));
            const arrayOrder = checkArrayOrder(values);
            if (arrayOrder !== 'unsorted' && arrayOrder !== 'short') {
                th.classList.add(arrayOrder);
            }
        });
    });
};
document.addEventListener('DOMContentLoaded', updateSortingClasses);
